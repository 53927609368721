<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{ $t('hat.sos.title') }}</span>
      </template>
      <div :class="$style.manageContent" slot="content">
        <FlexColContent>
          <div :class="$style.searchHeader">
            <a-range-picker
              style="width: 380px;"
              :show-time="{ format: 'HH:mm:ss' }"
              format="YYYY-MM-DD HH:mm:ss"
              v-model="dateRange"
              :ranges="presetTime"
              :allowClear="false"
              :placeholder="[
                $t('hat.clockRecord.startDate'),
                $t('hat.clockRecord.endDate'),
              ]"
              :disabled-date="disabledDate"
              @ok="handleSearchTime"
              @calendarChange="calendarPriceRangeChange"
            />
            <a-select
              show-search
              v-model="searchParams.userId"
              :placeholder="$t('hat.clockRecord.allMembers')"
              style="width: 200px;margin: 0 0 0 10px;"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
            >
              <a-select-opt-group>
                <span slot="label"
                  ><a-icon type="user" />{{
                    $t('hat.clockRecord.members')
                  }}</span
                >
                <a-select-option value="">
                  {{ $t('hat.clockRecord.allMembers') }}
                </a-select-option>
                <a-select-option
                  v-for="item in data.member"
                  :key="item.pkId"
                  :value="item.pkId"
                >
                  {{ item.memberName }}
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group>
                <span slot="label"
                  ><a-icon type="user" />{{
                    $t('hat.clockRecord.external')
                  }}</span
                >
                <!-- antDesign的bug不加这个option会报错 -->
                <a-select-option value="external">
                  {{ $t('hat.clockRecord.allMembers') }}
                </a-select-option>
                <a-select-option
                  v-for="item in data.external"
                  :key="item.pkId"
                  :value="item.pkId"
                >
                  {{ item.entMemberName }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
            <a-select
              v-model="searchParams.disposeStatus"
              style="width: 120px"
              @change="() => $refs.tableRef.refresh()"
            >
              <a-select-option value="">
                {{ $t('hat.sos.columns.allStates') }}
              </a-select-option>
              <a-select-option value="0">
                {{ $t('hat.sos.columns.pendingProcessing') }}
              </a-select-option>
              <a-select-option value="1">
                {{ $t('hat.sos.columns.processed') }}
              </a-select-option>
            </a-select>
          </div>
          <template #footer>
            <PaginationTable
              ref="tableRef"
              :columns="columns"
              :api="api"
              :extraKeys="{ showKey: false }"
              row-key="pkId"
              :slotKeys="['action', 'user', 'position']"
              :search-params="searchParams"
            >
              <template slot="action" slot-scope="{ record }">
                <div :class="$style.buttonGroups">
                  <a
                    @click="handleDispose(record)"
                    v-if="record.disposeStatus && $p.action('UPDATE')"
                    >{{ $t('hat.deviceManagement.edit') }}</a
                  >
                  <a
                    @click="handleDispose(record)"
                    v-if="!record.disposeStatus && $p.action('UPDATE')"
                    >{{ $t('hat.sos.handle') }}</a
                  >
                </div>
              </template>
              <template slot="user" slot-scope="{ record }">
                <div :class="$style.userCell">
                  <template v-if="record.userId">
                    <x-oss-image
                      :class="$style.avatar"
                      v-if="record.avatar"
                      :ossPath="record.avatar"
                    />
                    <span :class="[$style.avatar, $style.noAvatar]" v-else>{{
                      record.userName.charAt(0)
                    }}</span>
                  </template>
                  <span :class="$style.userName">{{ record.userName }}</span>
                </div>
              </template>
              <template slot="position" slot-scope="{ record }">
                <a
                  @click="handleShowMap(record)"
                  v-if="record.address"
                  :title="record.address"
                  >{{ record.address }}</a
                >
                <a
                  @click="handleShowMap(record)"
                  v-else-if="record.lat && record.lng && !record.address"
                  :title="record.address"
                  >未知</a
                >
                <span v-else>无</span>
              </template>
            </PaginationTable>
          </template>
        </FlexColContent>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { LayoutContent } from '@triascloud/x-blocks';
import { createModal, createFormModal } from '@triascloud/x-components';
import FlexColContent from '@/views/hat/components/flex-col-content';
import PaginationTable from '@/views/hat/components/pagination-table';
import {
  reqGetStaff,
  reqGetStaffInner,
  reqGetStaffExternal,
} from '@/services/attendance/list';
import { getSOSList } from '@/services/smart-hat/sos-record';
import SOSDispose from './components/sos-dispose.vue';
import SOSMap from './components/sos-map.vue';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    LayoutContent,
    FlexColContent,
    PaginationTable,
  },
})
export default class SOSCall extends Vue {
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  api = getSOSList;
  searchParams = {
    startTime: this.$moment().valueOf() - 6 * DAY,
    endTime: this.$moment().valueOf(),
    userId: '',
    disposeStatus: '',
  };
  dateRange = [
    // 默认显示一周
    this.$moment(new Date().getTime() - 6 * DAY).format('YYYY-MM-DD HH:mm:ss'),
    this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'),
  ];
  data = {
    external: [],
    member: [],
  };
  mounted() {
    this.staffList();
  }
  async staffList() {
    const member = await reqGetStaffInner();
    const external = await reqGetStaffExternal();
    this.data.member = member.records;
    this.data.external = external.records;
  }
  async fetch(value) {
    const result = await reqGetStaff(value);
    this.data = result;
  }
  async handleSearchTime(time) {
    this.searchParams.startTime = this.$moment(time[0]).valueOf();
    this.searchParams.endTime = this.$moment(time[1]).valueOf();
    this.$refs.tableRef.refresh();
  }
  handleSearch(value) {
    this.fetch(value);
  }
  async handleChange(v) {
    this.searchParams.userId = v === 'external' ? '' : v;
    this.$refs.tableRef.refresh();
  }
  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 360 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }
  async handleDispose(record) {
    const result = await createFormModal(() => <SOSDispose data={record} />, {
      width: 500,
      title: this.$t('hat.sos.form.title'),
      maskClosable: false,
    });
    if (result) {
      await this.$refs.tableRef.refresh();
    }
  }
  handleShowMap(record) {
    const modal = createModal(
      h =>
        h(SOSMap, {
          props: { record },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        width: 860,
        title: this.$t('hat.sos.detail'),
        maskClosable: false,
        className: this.$style.ManageModel,
      },
    );
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('hat.sos.columns.deviceId'),
        width: 150,
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('hat.broadcast.user'),
        width: 120,
        dataIndex: 'userName',
        ellipsis: true,
        scopedSlots: { customRender: 'user' },
      },
      {
        title: this.$t('hat.sos.columns.triggerTime'),
        width: 180,
        ellipsis: true,
        dataIndex: 'messageTime',
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: this.$t('hat.managementModal.event.position'),
        width: 180,
        ellipsis: true,
        dataIndex: 'lng',
        scopedSlots: { customRender: 'position' },
      },
      {
        title: this.$t('hat.broadcast.status'),
        dataIndex: 'disposeStatus',
        ellipsis: true,
        width: 180,
        customRender: text =>
          text
            ? this.$t('hat.sos.columns.processed')
            : this.$t('hat.sos.columns.pendingProcessing'),
      },
      {
        title: this.$t('hat.sos.columns.processedBy'),
        dataIndex: 'updateName',
        width: 100,
        ellipsis: true,
      },
      {
        title: this.$t('hat.sos.columns.processingResults'),
        dataIndex: 'disposeContent',
        width: 200,
        ellipsis: true,
      },
      {
        title: this.$t('electricBoxMock.columnsInfo.operation'),
        width: 120,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }
  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
    // height: 100%;
    :global {
      .ant-table-wrapper .ant-spin-container .ant-table-body,
      .ant-table-wrapper .ant-table-scroll .ant-table-body {
        flex: 1;
        overflow: auto !important;
        max-height: none !important;
      }
    }
    .searchHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      margin-bottom: 10px;
      .tipText {
        align-self: flex-end;
        color: var(--font-info);
        margin-right: auto;
      }
    }
    .userCell {
      color: var(--primary);
      .avatar {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        object-fit: cover;
        transform: translateY(28%);
      }
      .noAvatar {
        display: inline-block;
        color: #fff;
        background-color: var(--x-modal-select-avatar);
        text-align: center;
        line-height: 24px;
        font-size: 12px;
        transform: translateY(0);
      }

      .userName {
        margin: 0 5px;
      }
    }
  }
}
</style>
